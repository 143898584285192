<!--
 * @Author: your name
 * @Date: 2021-03-24 16:14:08
 * @LastEditTime: 2021-03-26 18:04:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\download.vue
-->
<template>
  <div class="box">
    <div class="info">
      <van-row gutter="20">
        <van-col span="15">App Name</van-col>
        <van-col span="9">Download</van-col>
        <van-col span="15">Mac Mini Laser Engraver</van-col>
        <van-col span="9"
          ><van-button
            type="info"
            size="small"
            @click="
              download(
                'https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini%20Laser%20Engraver.zip'
              )
            "
            >Download</van-button
          ></van-col
        >
        <van-col span="15">Windows Mini Laser Engraver</van-col>
        <van-col span="9"
          ><van-button
            type="info"
            size="small"
            @click="
              download(
                'https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini%20Laser%20Engraver%20for%20windows.zip'
              )
            "
            >Download</van-button
          ></van-col
        >
      </van-row>

      <!-- <div v-if="laq" class="tip">
        <span>检测到您是用微信浏览,请点击右上角使用默认浏览器打开.</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "download",
  data() {
    return {
      deta: `${new Date().getFullYear()}-${new Date().getMonth() +
        1}-${new Date().getDate()}`,

      laq: false, // 微信浏览器
    };
  },
  mounted() {
    document.title = "Mini Laser Engraver PC Download";

    // 浏览器类型
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf("micromessenger") !== -1) {
      this.laq = true;
    }
  },
  methods: {
    download(url) {
      if (this.laq) {
        alert("请用默认浏览器打开");
      } else {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.box {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: url("../../assets/download.png") no-repeat top center;
  background-size: cover;
}
.info {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  text-align: center;
  width: 18rem;
  max-width: 96vw;
  transform: translate(-50%, -50%);
}

.van-col {
  font-size: 0.2rem;
  height: 70px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  margin: -1px -1px 0 0;
  background: #fff;
  padding-left: 10px !important;
  padding-right: 0 !important;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  text-align: left;
}
.van-col:nth-child(1),
.van-col:nth-child(2),
.van-col:nth-child(3) {
  font-weight: 600;
}

.tip {
  margin-top: 50px;
}
.tip span {
  color: #fff;
  text-shadow: 0 0 2px #000;
}

.sms {
  margin: 60px 0 25px;
  font-size: 28px;
  color: #000;
  text-align: left;
}
</style>
